import React from 'react';
import { Container, Typography } from '@mui/material';
import JoinMeeting from './JoinMeeting';

const translations = {
  en: {
    joinMeetingTitle: "Join a Meeting",
  },
  fr: {
    joinMeetingTitle: "Rejoindre une Réunion",
  }
};

const JoinMeetingPage = ({ onJoin, language }) => {
  const t = translations[language];

  console.log("JoinMeetingPage: " + onJoin);
  return (
    <Container>
      <JoinMeeting onJoin={onJoin} language={language} />
    </Container>
  );
};

export default JoinMeetingPage;