import React, { useEffect, useRef, useState, useCallback } from 'react';
import { Container, Typography, Button, CircularProgress, Slider, IconButton, Box, Grid, Paper } from '@mui/material';
import { WebRTCAdaptor } from '@antmedia/webrtc_adaptor';
import io from 'socket.io-client';
import MicIcon from '@mui/icons-material/Mic';
import MicOffIcon from '@mui/icons-material/MicOff';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';

const translations = {
  en: {
    joining: "Joining meeting...",
    failedToJoin: "Failed to join meeting. Please try again.",
    meeting: "Meeting",
    role: "Role",
    status: "Status",
    volume: "Volume",
    playAudio: "Play Audio",
    pauseAudio: "Pause Audio",
    retryConnection: "Retry Connection",
    speaker: "Speaker",
    listener: "Listener",
    initializing: "Initializing",
    initialized: "Initialized",
    publishing: "Publishing",
    readyToPlay: "Ready to play",
    connectionClosed: "Connection closed",
    audioStreamAvailable: "Audio stream available",
    paused: "Paused",
    playing: "Playing",
    eventNotStarted: "The event has not started yet. Please wait for the speaker to begin.",
    noStreamExist: "No stream exists. The event may not have started.",
  },
  fr: {
    joining: "Rejoindre la réunion...",
    failedToJoin: "Échec de la connexion à la réunion. Veuillez réessayer.",
    meeting: "Réunion",
    role: "Rôle",
    status: "Statut",
    volume: "Volume",
    playAudio: "Jouer l'audio",
    pauseAudio: "Pause audio",
    retryConnection: "Réessayer la connexion",
    speaker: "Orateur",
    listener: "Auditeur",
    initializing: "Initialisation",
    initialized: "Initialisé",
    publishing: "Publication",
    readyToPlay: "Prêt à jouer",
    connectionClosed: "Connexion fermée",
    audioStreamAvailable: "Flux audio disponible",
    paused: "En pause",
    playing: "En lecture",
    eventNotStarted: "L'événement n'a pas encore commencé. Veuillez attendre que l'orateur commence.",
    noStreamExist: "Aucun flux n'existe. L'événement n'a peut-être pas commencé.",
  }
};

const Meeting = ({ token, language = 'en' }) => {
  const t = translations[language];
  const [isConnected, setIsConnected] = useState(false);
  const [audioStatus, setAudioStatus] = useState(t.initializing);
  const [meetingDetails, setMeetingDetails] = useState(null);
  const [participants, setParticipants] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isMuted, setIsMuted] = useState(false);
  const [volume, setVolume] = useState(100);
  const [isPlaying, setIsPlaying] = useState(false);
  const [eventStarted, setEventStarted] = useState(true);
  const webRTCAdaptorRef = useRef(null);
  const socketRef = useRef(null);
  const streamId = useRef(null);
  const audioRef = useRef(null);
  const localStreamRef = useRef(null);

  const initializeSocket = useCallback(() => {
    const socket = io("https://canoe.webpubsub.azure.com", {
      path: "/clients/socketio/hubs/canoeplateform",
      query: { token },
      withCredentials: true,
      transports: ['websocket', 'polling'],
    });

    socket.on('connect_error', (error) => {
      setAudioStatus(`${t.status}: ${error.message}`);
      setIsLoading(false);
    });

    socket.on('connect', () => {
      socket.emit('join-meeting', { token });
    });

    socket.on('joined-meeting', (data) => {
      if (data && data.antMediaStreamId) {
        streamId.current = data.antMediaStreamId;
        setMeetingDetails(data);
        setIsLoading(false);
      } else {
        setAudioStatus(`${t.status}: ${t.failedToJoin}`);
        setIsLoading(false);
      }
    });

    socket.on('participants', (participantList) => {
      setParticipants(participantList);
    });

    socket.on('user-joined', ({ userId }) => {
      setParticipants(prev => [...prev, userId]);
    });

    socket.on('user-left', ({ userId }) => {
      setParticipants(prev => prev.filter(id => id !== userId));
    });

    socket.on('disconnect', (reason) => {
      setIsConnected(false);
      setAudioStatus(`${t.status}: ${reason}`);
    });

    socket.on('error', (error) => {
      setAudioStatus(`${t.status}: ${error.message}`);
      setIsLoading(false);
    });

    socketRef.current = socket;

    return () => {
      socket.disconnect();
    };
  }, [token, t]);

  const initWebRTCAdaptor = useCallback(() => {
    if (!streamId.current) {
      setAudioStatus(`${t.status}: ${t.failedToJoin}`);
      return;
    }

    const websocketURL = `wss://${process.env.REACT_APP_ANT_MEDIA_SERVER_URL}/${process.env.REACT_APP_ANT_MEDIA_APP_NAME}/websocket`;
    const mediaConstraints = meetingDetails.role === 'speaker' 
  ? { video: false, audio: true }
  : { video: false, audio: false };
    const pc_config = { 'iceServers': [{ 'urls': 'stun:stun.l.google.com:19302' }] };
    const sdpConstraints = { OfferToReceiveAudio: true, OfferToReceiveVideo: false };

    const webRTCAdaptor = new WebRTCAdaptor({
      websocket_url: websocketURL,
      mediaConstraints: mediaConstraints,
      peerconnection_config: pc_config,
      sdp_constraints: sdpConstraints,
      callback: (info, obj) => {
        if (info === "initialized") {
          setAudioStatus(t.initialized);
          if (meetingDetails.role === 'speaker') {
            webRTCAdaptor.publish(streamId.current);
          } else {
            webRTCAdaptor.play(streamId.current);
          }
        } else if (info === "publish_started") {
          setAudioStatus(t.publishing);
          setIsConnected(true);
          setEventStarted(true);
          if (obj && obj.stream) {
            localStreamRef.current = obj.stream;
          }
        } else if (info === "play_started") {
          setAudioStatus(t.readyToPlay);
          setIsConnected(true);
          setEventStarted(true);
        } else if (info === "closed") {
          setAudioStatus(t.connectionClosed);
          setIsConnected(false);
          setIsPlaying(false);
        } else if (info === "newStreamAvailable") {
          setAudioStatus(t.audioStreamAvailable);
          setIsConnected(true);
          setEventStarted(true);
          if (audioRef.current && obj.stream) {
            audioRef.current.srcObject = obj.stream;
          }
        }
      },
      callbackError: (error, message) => {
        console.error("WebRTC error:", error, message);
        if (error === "no_stream_exist") {
          setAudioStatus(t.noStreamExist);
          setEventStarted(false);
        } else {
          setAudioStatus(`${t.status}: ${error} - ${message}`);
        }
        setIsPlaying(false);
      }
    });

    webRTCAdaptorRef.current = webRTCAdaptor;

    return () => {
      if (webRTCAdaptorRef.current) {
        webRTCAdaptorRef.current.stop();
      }
    };
  }, [meetingDetails, t]);

  useEffect(() => {
    const cleanup = initializeSocket();
    return () => {
      cleanup();
    };
  }, [initializeSocket]);

  useEffect(() => {
    if (meetingDetails && streamId.current) {
      initWebRTCAdaptor();
    }

    return () => {
      if (webRTCAdaptorRef.current) {
        webRTCAdaptorRef.current.stop();
      }
    };
  }, [meetingDetails, initWebRTCAdaptor]);

  const handleMuteToggle = useCallback(() => {
    if (webRTCAdaptorRef.current && webRTCAdaptorRef.current.muteLocalMic) {
      if (isMuted) {
        webRTCAdaptorRef.current.unmuteLocalMic();
        setIsMuted(false);
      } else {
        webRTCAdaptorRef.current.muteLocalMic();
        setIsMuted(true);
      }
    }
  }, [isMuted]);

  const handleVolumeChange = useCallback((event, newValue) => {
    setVolume(newValue);
    if (audioRef.current) {
      audioRef.current.volume = newValue / 100;
    }
  }, []);

  const handleRetry = useCallback(() => {
    if (webRTCAdaptorRef.current) {
      webRTCAdaptorRef.current.stop();
      initWebRTCAdaptor();
    }
  }, [initWebRTCAdaptor]);

  const handlePlayPauseClick = useCallback(() => {
    if (audioRef.current) {
      if (isPlaying) {
        audioRef.current.pause();
        setIsPlaying(false);
        setAudioStatus(t.paused);
      } else {
        audioRef.current.play().then(() => {
          setIsPlaying(true);
          setAudioStatus(t.playing);
        }).catch(e => {
          console.error("Error playing audio:", e);
          setAudioStatus(`${t.status}: ${e.message}`);
        });
      }
    }
  }, [isPlaying, t]);

  if (isLoading) {
    return (
      <Container sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
        <Typography variant="h6" sx={{ ml: 2 }}>{t.joining}</Typography>
      </Container>
    );
  }

  if (!meetingDetails) {
    return (
      <Container>
        <Typography variant="h6" color="error">
          {t.failedToJoin}
        </Typography>
      </Container>
    );
  }

  return (
    <Container maxWidth="md" sx={{ mt: 5 }}>
      <Paper elevation={3} sx={{ p: 4 }}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography variant="h4" sx={{ fontWeight: 'bold', mb: 2 }}>
              {t.meeting}: {meetingDetails.title}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="h6">{t.role}: {t[meetingDetails.role]}</Typography>
            <Typography variant="h6" color={isConnected ? 'success.main' : 'error.main'}>
              {t.status}: {audioStatus}
            </Typography>
          </Grid>

          <Grid item xs={12} sm={6} sx={{ textAlign: 'right' }}>
            {meetingDetails.role === 'speaker' && (
              <IconButton onClick={handleMuteToggle} color={isMuted ? "error" : "primary"}>
                {isMuted ? <MicOffIcon /> : <MicIcon />}
              </IconButton>
            )}

            {meetingDetails.role === 'listener' && (
              <>
                <Slider value={volume} onChange={handleVolumeChange} aria-labelledby="volume-slider" />
                <Typography id="volume-slider" gutterBottom>
                  {t.volume}: {volume}%
                </Typography>
              </>
            )}
          </Grid>
        </Grid>

        {!eventStarted && (
          <Typography variant="h6" color="warning.main" sx={{ mt: 2, textAlign: 'center' }}>
            {t.eventNotStarted}
          </Typography>
        )}

        <Box sx={{ textAlign: 'center', mt: 4 }}>
          <audio ref={audioRef} style={{ width: '100%', display: 'none' }}></audio>
          <Button
            variant="contained"
            color="primary"
            startIcon={isPlaying ? <PauseIcon /> : <PlayArrowIcon />}
            onClick={handlePlayPauseClick}
            sx={{ mb: 2, mr: 2 }}
            disabled={!eventStarted}
          >
            {isPlaying ? t.pauseAudio : t.playAudio}
          </Button>
          <Button variant="contained" color="secondary" onClick={handleRetry} sx={{ mb: 2 }}>
            {t.retryConnection}
          </Button>
        </Box>
      </Paper>
    </Container>
  );
};

export default Meeting;