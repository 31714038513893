import React, { useState, useEffect, useRef } from 'react';
import io from 'socket.io-client';

const CreateMeeting = ({ token }) => {
  const [title, setTitle] = useState('');
  const [createdMeeting, setCreatedMeeting] = useState(null);
  const [error, setError] = useState(null);
  const socketRef = useRef();

  useEffect(() => {
  console.log(token)
  socketRef.current = io("https://canoe.webpubsub.azure.com", {
          path: "/clients/socketio/hubs/canoeplateform",
          query: { token },
          withCredentials: true,
          transports: ['websocket', 'polling'],
        });

    socketRef.current.on('meeting-created', (data) => {
      setCreatedMeeting(data);
      setError(null);
    });

    socketRef.current.on('error', ({ message, details }) => {
      console.error('Error:', message, details);
      setError(message);
    });

    return () => {
      socketRef.current.disconnect();
    };
  }, [token]);

  const handleSubmit = (e) => {
    e.preventDefault();
    setError(null);
    socketRef.current.emit('create-meeting', { title, jwtToken: token });
  };

  const renderStreamInfo = (streamInfo) => {
    const relevantInfo = {
      "Stream ID": streamInfo.streamId,
      "Status": streamInfo.status,
      "Type": streamInfo.type,
      "Publish Type": streamInfo.publishType,
      "RTMP URL": streamInfo.rtmpURL,
      "WebRTC Viewer Limit": streamInfo.webRTCViewerLimit === -1 ? "Unlimited" : streamInfo.webRTCViewerLimit,
      "HLS Viewer Limit": streamInfo.hlsViewerLimit === -1 ? "Unlimited" : streamInfo.hlsViewerLimit,
      "DASH Viewer Limit": streamInfo.dashViewerLimit === -1 ? "Unlimited" : streamInfo.dashViewerLimit
    };

    return (
      <div>
        <h4>Stream Info:</h4>
        <table>
          <tbody>
            {Object.entries(relevantInfo).map(([key, value]) => (
              <tr key={key}>
                <td><strong>{key}:</strong></td>
                <td>{value}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  };

  return (
    <div>
      <h2>Create a New Meeting</h2>
      <form onSubmit={handleSubmit}>
        <input
          type="text"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          placeholder="Enter meeting title"
          required
        />
        <button type="submit">Create Meeting</button>
      </form>
      {error && <p style={{color: 'red'}}>{error}</p>}
      {createdMeeting && (
        <div>
          <h3>Meeting Created Successfully</h3>
          <p><strong>Meeting ID:</strong> {createdMeeting.meeting._id}</p>
          <p><strong>Title:</strong> {createdMeeting.meeting.title}</p>
          <p><strong>Speaker Token:</strong> {createdMeeting.meeting.speakerToken}</p>
          <p><strong>Listener Token:</strong> {createdMeeting.meeting.listenerToken}</p>
          <p><strong>Ant Media Stream ID:</strong> {createdMeeting.meeting.antMediaStreamId}</p>
          {createdMeeting.streamInfo && renderStreamInfo(createdMeeting.streamInfo)}
        </div>
      )}
    </div>
  );
};

export default CreateMeeting;