import React, { useState } from 'react';
import axios from 'axios';
import { TextField, Button, Container, Typography } from '@mui/material';

const translations = {
  en: {
    meetingToken: "Meeting Token",
    joinMeeting: "Join Meeting",
    joinMeetingTitle: "Join a Meeting",
  },
  fr: {
    meetingToken: "Jeton de Réunion",
    joinMeeting: "Rejoindre la Réunion",
    joinMeetingTitle: "Rejoindre une Réunion",
  }
};

const JoinMeeting = ({ onJoin, language }) => {
  const [token, setToken] = useState('');
  const t = translations[language];

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post('/api/meetings/join', { token });
      console.log("join meeting called with :" + JSON.stringify(response.data));
      onJoin(response.data);
    } catch (error) {
      console.error('Failed to join meeting:', error);
    }
  };

  return (
    <Container>
      <Typography variant="h4" align="center" gutterBottom>
        {t.joinMeetingTitle}
      </Typography>
      <form onSubmit={handleSubmit}>
        <TextField
          label={t.meetingToken}
          value={token}
          onChange={(e) => setToken(e.target.value)}
          fullWidth
          margin="normal"
          required
        />
        <Button type="submit" variant="contained" color="primary">
          {t.joinMeeting}
        </Button>
      </form>
    </Container>
  );
};

export default JoinMeeting;