// frontend/src/components/Admin.js
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Container, Typography } from '@mui/material';
import Login from './Login';
import Register from './Register';

const Admin = () => {
  const [token, setToken] = useState(null);
  const [isRegistering, setIsRegistering] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const storedToken = localStorage.getItem('token');
    if (storedToken) {
      setToken(storedToken);
    }
  }, []);

  const handleLogin = (newToken) => {
    setToken(newToken);
    localStorage.setItem('token', newToken);
  };

  const handleLogout = () => {
    setToken(null);
    localStorage.removeItem('token');
    navigate('/admin');
  };

  if (!token) {
    if (isRegistering) {
      return (
        <Container>
          <Register onRegisterSuccess={() => setIsRegistering(false)} />
          <Button variant="contained" onClick={() => setIsRegistering(false)}>Back to Login</Button>
        </Container>
      );
    }
    return (
      <Container>
        <Login setToken={handleLogin} />
      </Container>
    );
  }

  return (
    <Container>
      <Button variant="contained" color="secondary" onClick={handleLogout}>Logout</Button>
      <Typography variant="h4">Create a Meeting</Typography>
      <Button variant="contained" color="primary" onClick={() => navigate('/create-meeting', { state: { token } })}>
        Create Meeting
      </Button>
    </Container>
  );
};

export default Admin;