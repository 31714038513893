import React, { useState, useEffect } from 'react';
import { Route, Routes, Link, useLocation, useNavigate } from 'react-router-dom';
import { AppBar, Toolbar, Typography, Button, Container, Box } from '@mui/material';
import CreateMeetingPage from './components/CreateMeetingPage';
import JoinMeetingPage from './components/JoinMeetingPage';
import Meeting from './components/Meeting';
import Admin from './components/Admin';

const translations = {
  en: {
    joinMeeting: "Join Meeting",
    createMeeting: "Create Meeting",
    welcome: "Welcome to the Canoe platform. To join a meeting, click 'Join Meeting' and enter your token. Alternatively, you can create a new meeting.",
    switchLanguage: "Français",
  },
  fr: {
    joinMeeting: "Rejoindre la Réunion",
    createMeeting: "Créer une Réunion",
    welcome: "Bienvenue sur la plateforme Canoe. Pour rejoindre une réunion, cliquez sur 'Rejoindre la Réunion' et entrez votre jeton. Alternativement, vous pouvez créer une nouvelle réunion.",
    switchLanguage: "English",
  }
};

const App = () => {
  const [token, setToken] = useState(null);
  const [meeting, setMeeting] = useState(null);
  const [language, setLanguage] = useState('en');
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const langParam = params.get('lang');
    if (langParam && ['en', 'fr'].includes(langParam)) {
      setLanguage(langParam);
    }
  }, [location]);

  const toggleLanguage = () => {
    const newLang = language === 'en' ? 'fr' : 'en';
    setLanguage(newLang);
    navigate(`${location.pathname}?lang=${newLang}`);
  };

  if (meeting) {
    return <Meeting token={meeting.token} meetingId={meeting.meetingId} role={meeting.role} language={language} />;
  }

  const isHomePage = location.pathname === '/';
  const t = translations[language];

  return (
    <>
      <AppBar position="static">
        <Toolbar>
          <Typography variant="h6" style={{ flexGrow: 1 }}>
            Canoe
          </Typography>
          <Button color="inherit" component={Link} to={`/join-meeting?lang=${language}`}>{t.joinMeeting}</Button>
          <Button color="inherit" component={Link} to={`/admin?lang=${language}`}>{t.createMeeting}</Button>
          <Button color="inherit" onClick={toggleLanguage}>{t.switchLanguage}</Button>
        </Toolbar>
      </AppBar>
      <Container>
        {isHomePage ? (
          <Box display="flex" justifyContent="center" alignItems="center" height="80vh">
            <Typography variant="h4" align="center">
              {t.welcome}
            </Typography>
          </Box>
        ) : (
          <Box display="flex" justifyContent="center" alignItems="center" height="80vh">
            <Routes>
              <Route path="/join-meeting" element={<JoinMeetingPage onJoin={setMeeting} language={language} />} />
              <Route path="/admin" element={<Admin language={language} />} />
              <Route path="/create-meeting" element={<CreateMeetingPage token={token} language={language} />} />
            </Routes>
          </Box>
        )}
      </Container>
    </>
  );
};

export default App;